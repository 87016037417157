<template>
    <modal ref="Modalfinalizar" titulo="Finalizar pedido" icon="bandera" :btns="btns" no-cancelar no-aceptar @accion="accion_modal">
        <div class="row mx-0 justify-center f-15 text-general">
            ¿Desea dar por finalizado el pedido?
        </div>
        <div class="row mx-0 mt-3 justify-center f-15 text-general">
            Por favor confirme la fecha y hora de entrega del pedido
        </div>
        <div class="row f-14 my-4 mx-0 justify-center text-general">
            <div class="col">
                <p class="ml-2">Fecha de Entrega</p>
                <el-date-picker
                v-model="model.entrega_fecha"
                type="date"
                size="small"
                placeholder="Fecha"
                value-format="yyyy-MM-dd"
                value="yyyy-MM-dd"
                :clearable="false"
                :picker-options="pickerOptions"
                />
            </div>
            <div class="">
                <p class="ml-2">Hora de Entrega</p>
                <el-select v-model="model.entrega_horario" size="small" placeholder="Horario">
                    <el-option
                    v-for="item in horarios"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import moment from 'moment'
import Pedidos from '~/services/pedidos/pedidos-tienda'
import {mapGetters} from 'vuex'
export default {
    data(){
        let self = this
        return{
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment(self.pedido.updated_at).subtract(1,'days');
                }
            },
            horarios:[],
            model:{
                entrega_fecha:'',
                entrega_horario:''
            }
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
            pedido: 'pedidos/pedidos/pedido',
        }),
        btns(){
            let btns = [
                {
                    texto: 'Cerrar',
                    color: 'btn mx-2 bg-whitesmoke br-10 border text-dark',
                    accion: 'cancelar'
                },
                {
                    texto:'Finalizar',
                    color: 'btn mx-2 btn-aceptar text-white br-10 border-0',
                    accion:'finalizar'
                },

            ]
            if(this.pedido.pago_estado === 1){
                let credito = {
                    texto:'Otorgar Crédito',
                    color: 'btn mx-2 bg-success text-white br-10 border-0',
                    accion:'credito'
                }
                btns.push(credito)
            }
            return btns
        }
    },
    methods: {
        toggle(){
            this.model.entrega_fecha = this.pedido.entrega_fecha
            this.model.entrega_horario = this.pedido.entrega_horario
            this.set_franja()
            this.$refs.Modalfinalizar.toggle()
        },
        accion_modal(res){
            switch (res){
            case 'cancelar':
                this.$refs.Modalfinalizar.toggle()
                break;
            case 'finalizar':
                this.finalizar_pedido()
                break;
            case 'credito':
                this.$emit('credito')
                this.$refs.Modalfinalizar.toggle()
                break;
            default:

            }
        },
        async finalizar_pedido(){
            try {
                if(this.model.entrega_horario === ''){
                    this.notificacion('Advertencia','Por favor seleccione un horario','warning')
                    return
                }
                if(this.id_pedido === null || this.id_pedido === ''){
                    this.notificacion('Advertencia','Por favor seleccione un pedido','warning')
                    return
                }
                const {data} = await Pedidos.finalizar_pedido(this.id_pedido,this.model)
                this.$store.commit('pedidos/pedidos/set_id_pedido', null)
                this.$router.push({name:'tendero.pedidos'})
                this.notificacion('Mensaje','Pedido Finalizado correctamente','success')
                this.$refs.Modalfinalizar.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        async set_franja(){
            try {
                const {data} = await Pedidos.get_horarios()
                this.horarios = data.horarios

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
